.button_table {
	color: #2B80FF;
	background: transparent;
	font-weight: bold;
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.button_table {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.button_table {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.button_table {
		font-size: 15.18px;
	}
}