/* prueba */
.ant-btn-primary {
	color: #000 !important;
	background-color: #DDE4EB !important;
	box-shadow: 0 2px 0 rgb(5 145 255 / 10%) !important;
}

.ant-popover .ant-popover-inner {
	background-color: #00388B;
	width: 200px;

}

.ant-popover .ant-popover-arrow:before {
	background-color: #00388B;
}

.ant-popconfirm .ant-popconfirm-message-title {
	color: white;
	font-size: 12px;
	margin-inline-start: 9px;
}



.ant-popconfirm .ant-popconfirm-description {
	font-size: 11px;
	margin-left: 12px;
	margin-right: 12px;
	color: white;
}

.ant-popconfirm .ant-popconfirm-buttons button {
	background: none;
	color: white;
	font-size: 12px;
}