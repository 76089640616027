.form_style_background {
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;
}

.form {
	width: 42%;
	height: 100vh;
	display: flex;
	flex-flow: column !important;
	background: white;
	padding: 33.43px 28.69px;
	padding-top: 33.43px;
	z-index: 101;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	right: 0;
}