.container_general {
	padding: 31.7px 74.25px 0 30.25px;
	display: flex;
	flex-direction: column;
}

.container_data {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.container_description {
	display: flex;
	width: 70%;
	align-items: baseline;
	gap: 30px;
}


.sub_container {
	display: flex;
	flex-direction: column;
}

.text_title {
	margin-bottom: 6px;
	margin-top: 13px;
	color: #959595;
	font-size: 12px;
	font-weight: bold;
}

.description {
	display: flex;
	align-items: center;
}

.button {
	width: 140px;
	height: 34px;
	border: 1px solid #0060FF;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background: #DDE4EB;
	font-size: 12px;
	color: #001737;
}

.button:hover {
	background: #0060FF;
	color: white;

	.icon_button {
		color: white;
	}
}

.icon_button {
	color: #2B80FF;
	font-size: 14px;
}

.text_color {
	margin-bottom: 0px;
	color: #172B4D;
	font-size: 12px;
}

.text_label {
	margin-bottom: 0px;
	color: #172B4D;
	font-size: 13px;
	font-weight: bold;
}

.text_name {
	margin-bottom: 0px;
	color: #2B80FF;
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
}

.conatiner_table {
	margin-top: 41px;
}

.text_date {
	color: #959595;
	font-size: 11px;
}