.technician-mainPage-container {
	padding-top: 34.57px;
	display: flex;
	flex-flow: column;
}
.technician-mainPage-container .ant-table-filter-trigger{
    margin-right: 42px;
}

.technician-mainPage-container .single-pText {
	color: #5A607F;
	font-size: 14px;
	margin: 0 !important;
	padding: 0 !important;
	margin-bottom: 27.56px !important;
	padding-left: 42px !important;
}

@media screen and (min-width: 1280px) {
	.technician-mainPage-container .single-pText {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {
	.technician-mainPage-container .single-pText {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {
	.technician-mainPage-container .single-pText {
		font-size: 17.71px;
	}
}