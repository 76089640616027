.form__edit__category .select__custom__style {
    width: 100%;
    min-height: 40px !important;
}

.form__edit__category .select__custom__style .ant-select-selector {
    width: 100%;
    height: auto !important;
}

.form__edit__category .ant-select-multiple .ant-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}


.form__edit__category .ant-select-multiple .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.ant-select-dropdown .ant-select-item-option {
    display: flex;
    flex-direction: row;
}